import React, { useEffect, useState, useCallback } from "react";
import "./App.css";
import { RetellWebClient } from "retell-client-js-sdk";
import { useLocation } from "react-router-dom";

const webClient = new RetellWebClient();

const App = () => {
  const [isCalling, setIsCalling] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const agentId = queryParams.get("agentId") || "";
  const agentType = queryParams.get("agent_type") || "";
  const teamsMeetingId = queryParams.get("teams_meeting_id") || "";

  console.log("Query Params:", { agentId, agentType, teamsMeetingId });

  const handleAudio = useCallback((audio: Float32Array) => {
    console.log("🔊 Incoming Audio Data");
    // Handle the audio data as needed, e.g., visualize or process it
  }, []);

  useEffect(() => {
    const handleCallStarted = () => console.log("✅ Call Started");
    const handleAgentStart = () => console.log("🎤 Agent Started Talking");
    const handleAgentStop = () => console.log("🔇 Agent Stopped Talking");
    const handleCallEnded = () => {
      console.warn("⚠️ Call Ended");
      setIsCalling(false);
    };
    const handleError = (error: any) => {
      console.error("❌ WebSocket Error:", error);
      setIsCalling(false);
    };

    webClient.on("call_started", handleCallStarted);
    webClient.on("agent_start_talking", handleAgentStart);
    webClient.on("agent_stop_talking", handleAgentStop);
    webClient.on("audio", handleAudio);
    webClient.on("call_ended", handleCallEnded);
    webClient.on("error", handleError);

    return () => {
      webClient.off("call_started", handleCallStarted);
      webClient.off("agent_start_talking", handleAgentStart);
      webClient.off("agent_stop_talking", handleAgentStop);
      webClient.off("audio", handleAudio);
      webClient.off("call_ended", handleCallEnded);
      webClient.off("error", handleError);
    };
  }, [handleAudio]);

  const toggleCall = async () => {
    if (isCalling) {
      console.log("🛑 Stopping Call...");
      webClient.stopCall();
      setIsCalling(false);
    } else {
      console.log("📞 Starting Call...");
      try {
        const accessToken = await fetchAccessToken(agentId, agentType, teamsMeetingId);

        if (!accessToken) {
          console.error("❌ Missing Access");
          return;
        }

        console.log("🚀 Starting Call");

        webClient
          .startCall({
            accessToken,
            emitRawAudioSamples: true, // Enable raw audio samples if needed
          })
          .then(() => {
            console.log("✅ Call Started");
            setIsCalling(true);
          })
          .catch((error) => {
            console.error("❌ Failed to start call:", error);
          });
      } catch (error) {
        console.error("❌ Error Fetching Access Token:", error);
      }
    }
  };

  async function fetchAccessToken(agentId: string, agentType: string, teamsMeetingId: string): Promise<string> {
    if (!agentId || !agentType || !teamsMeetingId) {
      console.error("❌ Missing required parameters:", { agentId, agentType, teamsMeetingId });
      throw new Error("Missing agentId, agentType, or teamsMeetingId");
    }

    try {
      const response = await fetch("https://retell.backend.docsightai.com/retell-query/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ agentId, agent_type: agentType, teams_meeting_id: teamsMeetingId }),
      });

      if (!response.ok) {
        throw new Error(`❌ API Error: ${response.status}`);
      }

      const data = await response.json();
      return data.access_token;
    } catch (err: any) {
      console.error("❌ Fetch Access Token Failed:", err.message);
      throw new Error(err.message);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={toggleCall} disabled={!agentId || !teamsMeetingId}>
          {isCalling ? "Stop" : "Start"}
        </button>
      </header>
    </div>
  );
};

export default App;
